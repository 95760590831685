.myaccount {
  width: 100%;
  max-width: 1056px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 0 48px;
  box-sizing: border-box;
}
.myaccount h1{
  font-size: 40px;
}
.myaccount-info {
  border: 1px solid #292D39;
  background-color: #171B26;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 22px 28px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.myaccount p{
  color: #a0a0a0;
}
.myaccount-info p{
  margin: 0;
  font-size: 18px;
  color: white;
}
.myaccount-logout {
  display: flex;
  justify-content: end;
  padding: 20px 0;
}
.myaccount-logout button {
  color: #6B6B6B;
  border: none;
  background-color: #171b2600;
  cursor: pointer;
  text-decoration: underline;
  font-size: 16px;
}
.myaccount b {
  font-size: 20px;
}
.myaccount table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 10px;
}
/* 테이블에 스크롤 추가 */
.myaccount-table-container {
  margin-top: 50px;
  width: 100%;
  overflow-x: auto; /* 가로 스크롤 활성화 */
  border: 1px solid #222631; /* 외곽 테두리 */
  border-radius: 10px; /* 테두리에 곡선 적용 */
  margin-bottom: 70px;
}
.myaccount thead{
  background-color: #222631;
  border-radius: 10px;
}
.myaccount tr {
  border-radius: 10px;
}
.myaccount th {
  background-color: #222631;
  padding: 16px 16px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 300;
  color: #b1b1b1;
}
.myaccount tbody{
  width: 100%;
  background-color: #523e3e;
}
.myaccount .table-none {
  text-align: center;
  color: #b1b1b1;
  background-color: #171B26;
  margin: 0;
  width: 100%;
  padding: 30px;
}
.myaccount td {
  padding: 16px 28px;
  font-size: 15px;
  text-align: center;
  background-color: #171B26;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임(...) 효과 */
}
@media (max-width: 768px) {
  .myaccount {
    padding: 0 22px;
    box-sizing: border-box;
    margin-top: 60px;
    margin-bottom: 70px;
  }
  .myaccount h1{
    font-size: 28px;
  }
  .myaccount h2{
    font-size: 20px;
  }
  .myaccount-info {
    border: 1px solid #292D39;
    background-color: #171B26;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 20px 22px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .myaccount p{
    color: #a0a0a0;
    font-size: 14px;
  }
  .myaccount-info p{
    margin: 0;
    font-size: 14px;
    color: white;
  }
  .myaccount-logout {
    display: flex;
    justify-content: end;
    padding: 10px 0;
  }
  .myaccount-logout button {
    color: #6B6B6B;
    border: none;
    background-color: #171b2600;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;
  }
  .myaccount b {
    font-size: 16px;
  }
/* 모바일에서 테이블 스크롤 */
.myaccount-table-container {
  overflow-x: auto; /* 가로 스크롤 활성화 */
  -webkit-overflow-scrolling: touch; /* 스크롤 시 부드러운 움직임 */
}
  .myaccount table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-radius: 10px;
  }
  .myaccount thead{
    background-color: #222631;
    border-radius: 10px;
  }
  .myaccount tr {
    border-radius: 10px;
  }
  .myaccount th {
    background-color: #222631;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
  }
  .myaccount td {
    font-size: 13px;
    text-align: center;
    background-color: #171B26;
  }
}