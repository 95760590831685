/* 기본 스타일 */
.header {
  background-color: #0D111D;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 48px;
  height: 90px;
  border-bottom: 1px solid #292D39;
  box-sizing: border-box;
  animation: fadeIn 1s ease-in-out;
}
.logo-img {
  width: 130px;
  animation: popIn 0.6s ease forwards;
}
.header a {
  color: rgb(229, 229, 229);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
}
.header a:hover {
  color: #ffffff;
  transform: scale(1.1);
}
.header .header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}
.header .header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
  padding-bottom: 4px;
}
.header .header-btn {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.header .header-btn a {
  color: rgb(120, 120, 120);
  font-size: 15px;
  border: 1px solid rgb(120, 120, 120);
  min-width: 60px;
  padding: 6px 12px 8px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.header .header-btn a:hover {
  background-color: #383939;
  transform: scale(1.1);
}

/* 애니메이션 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* 모바일 스타일 */
.hamburger-icon {
  display: none;
  font-size: 24px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.hamburger-icon:hover {
  transform: scale(1.1);
}

.close-icon {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.close-icon:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .header-right {
    display: none; /* 모바일에서 기본 버튼 숨기기 */
  }
  .header .header-btn {
    display: none; /* 모바일에서 기본 버튼 숨기기 */
  }
  .hamburger-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 6px;
    margin-right: -2px;
    animation: fadeIn 0.6s ease-in-out;
  }
  .header {
    background-color: #0D111D;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 22px;
    height: 60px;
    box-sizing: border-box;
  }
  .logo-img {
    width: 100px;
  }
  .header-nav {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    gap: 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1;
  }

  .header-nav.open {
    transform: translateX(0);
    animation: slideInRight 0.3s ease forwards;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
  }

  .header-nav.open .nav-item {
    animation: fadeIn 0.5s ease forwards;
    animation-delay: calc(0.2s * var(--item-index)); /* 각 메뉴의 순차적 지연 */
  }

  /* 햄버거 메뉴가 열렸을 때 보이는 회원가입/로그인 버튼 */
  .header-btn-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    animation: fadeIn 0.6s ease forwards;
  }

  .close-icon {
    position: absolute;
    top: 7px;
    right: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideInLeft 0.3s ease;
    z-index: 1;
  }
}
