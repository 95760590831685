.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}
.signup h2{
  font-size: 40px;
  margin: 60px 0 36px;
}
.signup label{
  font-size: 16px;
}
.signup form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  box-sizing: border-box;
}
.signup form input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 16px 12px;
  margin-top: 14px;
  font-size: 18px;
  border: 1px solid #292D39;
  background-color:#ffffff00;
  color: white;
  height: 50px;
}
.signup form button {
  width: 100%;
  box-sizing: border-box;
  margin-top: 30px;
  padding: 16px;
  font-size: 16px;
  background-color: #2B66F6;
  border: none;
  border-radius: 10px;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.signup p{
  text-align: center;
  color: #636271;
}
.signup a{
  text-decoration: underline;
  color: #636271;
  font-weight: 600;

}
@media (max-width: 768px) {
  .signup{
    padding: 0 22px;
    box-sizing: border-box;
  }
  .signup h2{
    font-size: 28px;
    margin: 40px 0 24px;
  }
}