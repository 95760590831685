/* 애니메이션 */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* 홈 페이지 히어로 섹션 */
.home{
  padding: 0 40px;
  box-sizing: border-box;
}
.home-hero {
  background-color: #0D111D;
  width: 100%;
  padding: 90px 0;
}
.home .container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home .container h1 {
  font-size: 48px;
  margin-bottom: 12px;
  margin-top: 40px;
  animation: fadeIn 1s ease-in-out;
}
.home .container p {
  margin: 4px;
  color: #6B6B6B;
  font-size: 24px;
  animation: fadeIn 1s ease-in-out 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;
  line-height: 140%;
}
.home .container .home-note{
  margin-top: 60px;
  text-align: center;
}
.home .container .home-note p{
  color: #6B6B6B;
  font-size: 16px;
  text-align: center;
}
.home .container .home-note p u{
  color: white;
  font-weight: 400;
}
.home .container .home-note p a{
  color: #b4b4b4;
  text-decoration: none;
}
.home form {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 36px;
  
}
.home form input {
  width: 100%;
  padding: 18px 22px;
  background-color: #171B26;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  color: #9C9C9C;
  box-sizing: border-box;  
  border: 1px solid transparent; /* 기본 테두리를 투명하게 설정 */
}
.home form input:disabled {
  cursor: not-allowed;
  color: #9C9C9C;
}
/* 로그인 전 호버 시 인풋 흔들림 애니메이션 */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}
.home form input:disabled:hover {
  border: 1px solid #FF0000; /* 금지 느낌의 빨간 테두리 */
  animation: shake 0.4s ease-in-out;
  background-color: #2C2C2C; /* 살짝 어두운 배경으로 변환 */
  box-sizing: border-box;
}
.home form button {
  padding: 12px 12px;
  min-width: 220px;
  border-radius: 10px;
  background-color: #2B66F6;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.home form button:hover {
  background-color: #1A4BB8;
  transform: scale(1.05);
}
/* 비디오 목록 */
.videos {
  width: 100%;
  max-width: 960px;
  margin: 60px auto 70px;
  animation: fadeIn 1s ease-in-out;
}
.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video-item {
  flex: 0 1 calc(25% - 10px);
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  animation: popIn 1.1s ease-in-out;
}
.video-item:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.video-image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}
.video-image:hover {
  transform: scale(1.05);
}

/* 로딩 화면 */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: fadeIn 0.5s ease;
}

.loader {
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  animation: popIn 0.5s ease-in-out;
}

/* 소개 섹션 */
.introduce {
  width: 100%;
  max-width: 960px;
  margin: 60px auto 70px;
  background-color: #171B26;
  border-radius: 10px;
  border: 1px solid #383939;
  padding: 48px;
  box-sizing: border-box;
  margin-bottom: 150px;
  animation: fadeIn 1s ease-in-out;
}

.introduce h2, .introduce h3, .introduce p {
  animation: fadeIn 1s ease-in-out;
}
.introduce h2 {
  color: #ffffff;
}
.introduce h3 {
  margin: 24px 0 16px;
  text-align: left;
  color: #ffffff;
}
.introduce p, .introduce li {
  color: #b4b4b4;
}
.home .progressive-color {
  color: #D5474F; /* 빨간색 */
}

.home .conservative-color {
  color: #2B66F6; /* 파란색 */
}

.home .neutral-color {
  color: #ECECEC; /* 중립 회색 */
}
/* 에러 메시지 */
.home-hero .container .error-msg {
  font-size: 16px;
  text-align: left;
  width: 100%;
  padding-left: 22px;
  margin-top: 20px;
  box-sizing: border-box;
  color: red;
  animation: none;
  opacity: 1; /* 애니메이션 제거 후에도 보이게 설정 */
}
@media (max-width: 960px) {
  .home .video-container {
    display: grid;
    gap: 30px; /* 요소 간의 간격 */
    grid-template-columns: repeat(2, 1fr); /* 기본 3개 */
  }
}
/* 모바일 스타일 */
@media (max-width: 768px) {
  .home{
    padding: 0 22px;
    box-sizing: border-box;
  }
  .home-hero {
    background-color: #0D111D;
    padding: 60px 0px;
    box-sizing: border-box;
    text-align: center;
  }
  .home .container {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  .home .container h1 {
    width: 250px;
    font-size: 28px;
    margin-bottom: 4px;
    margin-top: 0;
  }
  .home .container p {
    width: 100%;
    margin: 12px;
    color: #ffffff;
    font-size: 15px;
  }
  .home .container .home-note{
    margin-top: 30px;
  }
  .home .container .home-note p{
    color: #6B6B6B;
    font-size: 13px;
    text-align: center;
    margin: 0;
  }
  .home form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
  }
  .home form input {
    width: 100%;
    padding: 14px 20px;
    box-sizing: border-box;
    background-color: #171B26;
    border-radius: 10px;
    font-size: 15px;
    color: #9C9C9C;
    text-align: center;
  }
  .home form button {
    padding: 12px 12px;
    min-width: 220px;
    border-radius: 10px;
    background-color: #2B66F6;
    color: white;
    font-weight: 500;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  .videos {
    width: 100%;
    max-width: 960px;
    margin: 0 auto 70px;
  }
  .home .video-container {
    display: grid;
    gap: 20px; /* 요소 간의 간격 */
    grid-template-columns: repeat(1, 1fr); /* 기본 4개 */
  }
  
  .video-item {
    justify-content: space-between;
    flex: 0 1 calc(25% - 10px); /* 25% 너비 - 간격 */
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 10px;
  }
  h3 {
    font-size: 1em;
    margin-top: 0.5em;
    text-align: center;
  }
  .videos h2{
    font-size: 18px;
  }
  .load-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  .container.loading {
    position: relative;
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .loader {
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .loader p {
    color: white;
    font-size: 20px;
    font-weight: 600;
  }
  .introduce{
    width: 100%;
    max-width: 960px;
    margin: 0;
    background-color: #171B26;
    border-radius: 10px;
    border: 1px solid #383939;
    padding: 22px;
    box-sizing: border-box;
    margin-bottom: 70px;
  }
  .home .container .error-msg{
    box-sizing: border-box;
    text-align: center;
    padding: 0;
    font-size: 13px;
  }
  .introduce h2{
    margin-top: 0;
    color: #ffffff;
    font-size: 20px;
  }
  .introduce h3{
    margin: 24px 0 16px;
    text-align: left;
    color: #ffffff;
    font-size: 16px;
  }
  .introduce p{
    line-height: 160%;
    font-size: 14px;
    color: #b4b4b4;
  }
  .introduce li{
    margin: 10px;
  }
}
