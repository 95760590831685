@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.discussion {
  width: 100%;
  max-width: 1056px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;
  margin-bottom: 150px;
  padding: 0 48px;
  box-sizing: border-box;
  
}
.discussion .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.discussion h1 {
  width: 100%;
  margin: 0 0 16px;
  font-size: 40px;
  text-align: center;
  animation: popIn 0.8s ease-out forwards;
}
.discussion h2{
   margin-top: 48px;
   margin-bottom: 12px;
}
.discussion p{
  color: #6C6C6C;
  margin: 0;
  margin-bottom: 60px;
  animation: popIn 0.8s ease-out forwards;
}
/* 결과에 따른 색상 스타일 */
.progressive-color {
  color: #D5474F; /* 빨간색 */
}

.conservative-color {
  color: #2B66F6; /* 파란색 */
}

.neutral-color {
  color: #ECECEC; /* 중립 회색 */
}
iframe{
  width: 480px;
  height: 270px;
  margin-top: 60px;
  animation: fadeInUp 1.2s ease-in-out;
}
.discussion .tab {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 70px;
}
.discussion .tab button {
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #292A2A;
  font-size: 16px;
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
  color: #636271;
  border-radius: 10px;
}
.discussion .tab button.active {
  font-weight: bold;
  background-color: #171B26;
  color: white;
}
.discussion .tab-content {
  background-color:#171B26;
  box-sizing: border-box;
  border: 1px solid #292A2A;
  padding: 36px;
  width: 100%;
  border-radius: 10px;
  margin-top: 48px;
  line-height: 140%;
  color: #e0e0e0;
  animation: fadeIn 0.8s ease-in-out;
}
.comments {
  width: 100%;
  margin-top: 120px;
}
.comments form {
  display: flex;
  flex-direction: column;
  border: 1px solid #222631;
  background-color: #171B26;
  box-sizing: border-box;
  padding: 36px;
  border-radius: 10px;
}
.comments form b {
  padding-bottom: 20px;
}
.comments form textarea {
  border: none;
  outline: none;
  background-color: #222631;
  padding: 24px;
  color: white;
  border-radius: 10px;
  font-size: 16px;
}
.comments form .button {

  display: flex;
  flex-direction: row;
  justify-content: end;
}
.comments form button {
  padding: 14px 24px;
  margin-top: 20px;
  background-color: #2B66F6;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
}
.comments-edit-btn {
  display: flex;
  justify-content: end;
  gap: 12px;
}
.comments ul {
  list-style: none;
  padding: 0;
  margin-top: 64px;
  margin-bottom: 70px;
}
.comments li {
  padding: 36px;
  margin: 32px 0;
  background-color: #171B26;
  border-radius: 10px;
  font-size: 16px;
  color: white;
}
.comments .comments-meta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.comments-meta-left {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  font-size: 18px;
}
.comments-meta-left .comment-date{
  color: #636271;
  font-size: 16px;
}
.comments-meta-left small{
  font-size: 16px;
}
.discussion .comment-content{
  color: white;
  margin-top: 18px;
  margin-bottom: 0;
}
.control-btn{
  background-color: #171b2600;
  color: #636271;
  text-decoration: underline;
  cursor: pointer;
  border: none;
  font-size: 16px;
}

.comments-none {
  text-align: center;
  padding: 70px;
  margin: 0;
}
.discussion .edit-comment{
  padding: 0;
  border: none;
  gap: 0;
}
.discussion .edit-comment button{
  margin-top: 0;
}
.p-red{
  color: #D5474F;
}
.c-blue{
  color: #2B66F6;
}
.n-white{
  color: white;
}
.comment-more-btn{
  display: block;
  margin: 16px auto;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
  background-color: #2B66F6;
  color: white;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.comment-more-btn:hover {
  background-color: #1A4BB8;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .discussion {
    margin-top: 60px;
    margin-bottom: 70px;
    padding: 0 22px;
    box-sizing: border-box;
  }
  .discussion h1 {
    width: 100%;
    margin: 0 0 16px;
    font-size: 24px;
    text-align: center;
  }
  .discussion h2{
     margin-top: 48px;
     margin-bottom: 12px;
     font-size: 20px;
  }
  .discussion p{
    color: #6C6C6C;
    margin: 0;
    margin-bottom: 20px;
    font-size: 13px;
    text-align: center;
  }
  iframe{
    width: 320px;
    height: 180px;
    margin-top: 60px;
  }
  .discussion .tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12px;
    margin-top: 70px;
  }
  .discussion .tab button {
    width: 100%;
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #292A2A;
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    color: #636271;
    border-radius: 10px;
  }
  .discussion .tab button.active {
    font-weight: bold;
    background-color: #171B26;
    color: white;
  }
  .discussion .tab-content {
    background-color:#171B26;
    box-sizing: border-box;
    border: 1px solid #292A2A;
    padding: 24px;
    width: 100%;
    border-radius: 10px;
    margin-top: 24px;
  }
  .comments {
    width: 100%;
    margin-top: 10px;
  }
  .comments h2{
    font-size: 18px;
  }
  .comments form {
    display: flex;
    flex-direction: column;
    border: 1px solid #222631;
    background-color: #171B26;
    box-sizing: border-box;
    padding: 22px;
    border-radius: 10px;
  }
  .comments form b {
    padding-bottom: 20px;
  }
  .comments form textarea {
    border: none;
    outline: none;
    background-color: #222631;
    padding: 22px;
    color: white;
    border-radius: 10px;
    font-size: 15px;
  }
  .comments form .button {
    padding: 0 0;
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  .comments form button {
    padding: 12px 18px;
    margin-top: 20px;
    background-color: #2B66F6;
    color: white;
    border: none;
    font-size: 13px;
    font-weight: 600;
    border-radius: 10px;
  }
  .comments-edit-btn {
    display: flex;
    justify-content: end;
    gap: 12px;
  }
  .comments ul {
    list-style: none;
    padding: 0;
    margin-top: 64px;
    margin-bottom: 30px;
  }
  .comments li {
    padding: 22px;
    margin: 22px 0;
    background-color: #171B26;
    border-radius: 10px;
    font-size: 16px;
    color: white;
  }
  .comments .comments-meta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }
  .comments-meta-left {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: start;
    font-size: 13px;
  }
  .comments-meta-left .comment-date{
    color: #636271;
    font-size: 13px;
  }
  .comments-meta-left small{
    font-size: 13px;
  }
  .discussion .comment-content{
    color: white;
    margin-top: 18px;
    margin-bottom: 0;
    text-align: left;
    font-size: 15px;
  }
  .control-btn{
    background-color: #171b2600;
    color: #636271;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    font-size: 13px;
    padding: 0 8px;
  }
  .control-btn:last-child{
    padding: 0;
  }
  .comments-none {
    text-align: center;
    padding: 70px;
    margin: 0;
  }
  .p-red{
    color: #D5474F;
  }
  .c-blue{
    color: #2B66F6;
  }
  .n-white{
    color: white;
  }
  .comment-more-btn{
    display: block;
    margin: 16px auto;
    padding: 12px 20px;
    font-size: 13px;
    cursor: pointer;
    font-weight: 600;
    background-color: #2B66F6;
    color: white;
    border: none;
    border-radius: 10px;
  }

}