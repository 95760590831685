.auth-header {
  background-color: #0D111D;
  border-bottom: 1px solid #292D39;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90px;
}
.auth-header img{
  width: 130px;
}
@media (max-width: 768px) {
  .auth-header{
    height: 60px;
  }
  .auth-header img{
    width: 100px;
  }
}