.discussions {
  width: 100%;
  max-width: 1056px;
  margin: 0 auto 70px;
  margin-top: 100px;
  margin-bottom: 150px;
  padding: 0 48px;
  box-sizing: border-box;
}
.discussions h1{
  font-size: 40px;
  margin: 0;
}
.discussions p{
  font-size: 18px;
  line-height: 150%;
  font-weight: 300;
  margin-bottom: 80px;
}
.video-container {
  display: grid;
  gap: 20px; /* 요소 간의 간격 */
  grid-template-columns: repeat(4, 1fr); /* 기본 4개 */
}

.video-item {
  justify-content: space-between;
  flex: 0 1 calc(25% - 16px); /* 25% 너비 - 간격 */
  box-sizing: border-box;
  margin-bottom: 54px;
  cursor: pointer;
  padding: 16px 16px 24px;
  border-radius: 10px;
  border: none;
  background-color: #171B26;
}
.video-item h3{
  text-align: left;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; /* 3줄 이후부터 말줄임 표시 */
    line-height: 1.3em; /* 줄 간격 조절 */
    height: 3.9em; /* 3줄 높이에 맞게 설정 */
    font-size: 16px;
    font-weight: 300;
}
.video-item p{
  color: #6B6B6B;
  font-size: 13px;
  margin-bottom: 8px;
}
.video-image {
  width: 100%;
  height: fit-content;
}
/* 결과에 따른 색상 스타일 */
.discussions .progressive-color {
  color: #D5474F; /* 빨간색 */
}

.discussions .conservative-color {
  color: #2B66F6; /* 파란색 */
}

.discussions .neutral-color {
  color: #ECECEC; /* 중립 회색 */
}
h3 {
  font-size: 1em;
  margin-top: 0.5em;
  text-align: center;
}

.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 16px 36px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 500;
  background-color: #2B66F6;
  color: white;
  border: none;
  border-radius: 10px;
}

@media (max-width: 960px) {
  .video-container {
    display: grid;
    gap: 30px; /* 요소 간의 간격 */
    grid-template-columns: repeat(3, 1fr); /* 기본 4개 */
  }
}
@media (max-width: 768px) {
  .discussions {
    padding: 0 22px;
    box-sizing: border-box;
    margin-top: 60px;
    margin-bottom: 70px;
  }
  .discussions h1{
    font-size: 28px;
  }
  .discussions p{
    font-size: 15px;
    line-height: 140%;
    margin-bottom: 50px;
  }
  .video-container {
    display: grid;
    gap: 30px; /* 요소 간의 간격 */
    grid-template-columns: repeat(1, 1fr); /* 기본 4개 */
  }
  
  .video-item {
    justify-content: space-between;
    flex: 0 1 calc(25% - 16px); /* 25% 너비 - 간격 */
    box-sizing: border-box;
    margin-bottom: 0px;
    cursor: pointer;
    padding: 16px;
    border-radius: 10px;
    border: none;
    background-color: #171B26;
  }
  .video-item h3{
    text-align: left;
    display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3; /* 3줄 이후부터 말줄임 표시 */
      line-height: 1.3em; /* 줄 간격 조절 */
      height: 3.9em; /* 3줄 높이에 맞게 설정 */
      font-size: 16px;
      font-weight: 300;
  }
  .video-item p{
    color: #6B6B6B;
    font-size: 13px;
    margin-bottom: 8px;
  }
  .video-image {
    width: 100%;
    height: fit-content;
  }
  
  h3 {
    font-size: 1em;
    margin-top: 0.5em;
    text-align: center;
  }
  
  .load-more-button {
    display: block;
    margin: 20px auto;
    padding: 12px 20px;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    background-color: #2B66F6;
    color: white;
    border: none;
    border-radius: 10px;
  }
}