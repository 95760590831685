.bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}

.bar-segment {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 12px;
    width: 0; /* 시작 너비를 0으로 설정 */
  animation: fillBar 1s ease-out forwards; /* 애니메이션 추가 */
}

.segment-1 {
  background-color: #D5474F;
}

.segment-2 {
  background-color: #ECECEC;
}

.segment-3 {
  background-color: #2B66F6;
}
