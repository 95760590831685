.analyze {
  width: 100%;
  max-width: 1056px;
  margin: 0 auto 70px;
  margin-top: 100px;
  padding: 0 48px;
  box-sizing: border-box;
}
.analyze h1{
  font-size: 40px;
  margin: 0;
}
.analyze p{
  font-size: 18px;
  line-height: 150%;
  font-weight: 300;
  margin-bottom: 48px;
}
.analyze .analyze-note{
  color: #6B6B6B;
  margin: 0;
  font-size: 16px;
}
.analyze .analyze-note u{
  color: white;
  font-weight: 400;
}
.analyze .analyze-note a{
  color: #b4b4b4;
  text-decoration: none;
  margin-left: 8px;

}
.analyze form {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.analyze form input {
  width: 100%;
  padding: 18px 22px;
  background-color: #171B26;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  color: #9C9C9C;
  box-sizing: border-box;  
  border: 1px solid transparent; /* 기본 테두리를 투명하게 설정 */
}
.analyze form input:disabled {
  cursor: not-allowed;
  color: #9C9C9C;
}
/* 로그인 전 호버 시 인풋 흔들림 애니메이션 */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  20%, 60% { transform: translateX(-5px); }
  40%, 80% { transform: translateX(5px); }
}
.analyze form input:disabled:hover {
  border: 1px solid #FF0000; /* 금지 느낌의 빨간 테두리 */
  animation: shake 0.4s ease-in-out;
  background-color: #2C2C2C; /* 살짝 어두운 배경으로 변환 */
  box-sizing: border-box;
}
.analyze form button {
  padding: 12px 12px;
  min-width: 220px;
  border-radius: 10px;
  background-color: #2B66F6;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.analyze form button:hover {
  background-color: #1A4BB8;
  transform: scale(1.05);
}
.howto-extract{
  width: 100%;
  max-width: 1056px;
  margin: 0 auto 70px;
  margin-top: 120px;
  margin-bottom: 150px;
  padding: 0 48px;
  box-sizing: border-box;
}
.howto-extract h3{
  font-size: 24px;
  text-align: left;
}
.howto-extract-content{
  background-color: #171B26;
  border: 1px solid #292D39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 48px;
}
.howto1-img{
  margin-bottom: 36px;
  max-width: 100%;
}
.howto2-img{
  max-width: 100%;
}
.howto-extract-content h4{
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;
}
.error-msg{
  box-sizing: border-box;
  padding-left: 22px;
  font-size: 16px;
}

@media (max-width: 768px) {
  .analyze {
    width: 100%;
    max-width: 960px;
    margin: 0 auto 70px;
    margin-top: 60px;
    padding: 0 22px;
    box-sizing: border-box;
  }
  .analyze h1{
    font-size: 28px;
    margin: 0;
  }
  .analyze p{
    font-size: 15px;
    line-height: 140%;
    font-weight: 300;
    margin-bottom: 22px;
  }
  .analyze .analyze-note{
    color: #6B6B6B;
    margin: 0;
    font-size: 13px;
  }
  .analyze .analyze-note u{
    color: white;
    font-weight: 400;
  }
  .analyze .analyze-note a{
    color: #b4b4b4;
    text-decoration: none;
    margin-left: 4px;
  
  }
  .analyze form {
    margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .analyze form input {
    width: 100%;
    padding: 14px 20px;
    box-sizing: border-box;
    background-color: #171B26;
    border-radius: 10px;
    border: none;
    font-size: 15px;
    text-align: center;
    color: #9C9C9C;
  }
  .analyze form button {
    padding: 12px 12px;
    min-width: 220px;
    border-radius: 10px;
    background-color: #2B66F6;
    color: white;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  .howto-extract{
    width: 100%;
    max-width: 960px;
    margin: 0 auto 70px;
    margin-top: 50px;
    margin-bottom: 70px;
    padding: 0 22px;
    box-sizing: border-box;
  }
  .howto-extract h3{
    font-size: 16px;
    text-align: left;
  }
  .howto-extract-content{
    background-color: #171B26;
    border: 1px solid #434650;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 28px;
  }
  .howto-extract-content h4{
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    margin-bottom: 20px;
  }
  .error-msg{
    box-sizing: border-box;
    text-align: center;
    padding: 0;
    font-size: 13px;
  }
}