.auth-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: 292D39;
  border-top: 1px solid #292D39;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  color: rgb(120, 120, 120);
  font-size: 14px;
}