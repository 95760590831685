.footer {
  width: 100%;
  background-color: #0D111D;
  border-top: 1px solid #292D39;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px;
  box-sizing: border-box;
  color: rgb(120, 120, 120);
  font-size: 12px;
}

.footer .logo-img{
  width: 100px;
  margin-right: 0;
}